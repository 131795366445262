// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import Carousel from 'antd/lib/carousel'
import 'antd/lib/carousel/style/css'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import Link from '../components/link'
import '../components/link/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import { updateWebsiteState as updateWebsiteStateFx } from '../state/actions'

import AboutPageSchema from '../components/schema/about-page-schema'
import ContactPageSchema from '../components/schema/contact-page-schema'

import ArrowLeft from '../components/svg/arrow-left'
import ArrowRight from '../components/svg/arrow-right'

import about from '../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'About',
  slug: '/about',
  abstract: about.text,
  breadcrumbs: [
    { title: 'Cover Page', slug: '' },
    { title: 'About', slug: '/about' },
  ],
}

// // ----------------------------------------------------------------------------
// // --------------------------------------------------------------------- Images
// // ----------------------------------------------------------------------------
// export const query = graphql`
//   query {
//     img7: file(relativePath: { eq: "book/img7.png" }) {
//       childImageSharp {
//         gatsbyImageData(
//           layout: FULL_WIDTH
//           placeholder: TRACED_SVG
//           formats: [AUTO, WEBP, AVIF]
//         )
//       }
//     }
//   }
// `

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
const Page = ({ websiteState, updateWebsiteState, ...props }) => {
  const noop = true

  // const resources = [
  //   { img: props.data.img1 },
  //   { img: props.data.img2 },
  //   { img: props.data.img3 },
  //   { img: props.data.img4 },
  //   { img: props.data.img5 },
  //   { img: props.data.img6 },
  //   { img: props.data.img7 },
  // ]

  // const sliderConf = {
  //   infinite: false,
  //   slidesToScroll: 1,
  //   dots: true,
  //   arrows: true,
  //   prevArrow: <ArrowLeft />,
  //   nextArrow: <ArrowRight />,
  //   customPaging: (i) => {
  //     return (
  //       <a aria-label={`Go to slide ${i + 1}`} role="link">
  //         <GatsbyImage image={getImage(resources[i].img)} />
  //       </a>
  //     )
  //   },
  //   dotPosition: 'bottom',
  // }

  return (
    <StandardPageWrapper pageSchema={pageSchema} {...props}>
      <AboutPageSchema />
      <ContactPageSchema />
      <h1>About this book</h1>
      <p className="dropcap">
        <span>M</span>other taught that every flower has its own expression of a
        quality which is also in human beings. She was able to experience a
        flower’s spiritual essence, and she found that people, flowers, and the
        earth have the same spiritual aspirations. Their very existence
        expresses the vast purpose of the manifestation of the whole creation.
        As spiritual teachers and guides, Mother and Sri Aurobindo taught that
        the Supreme All-Consciousness and All-Love became the creation itself by
        successively becoming less and less conscious, until the final
        expression of unconsciousness became the most dense part of the
        creation, which is unconscious physical matter. The underlying purpose
        is to become all-conscious again for the joy of being and the delight of
        the process of return. Although physical matter is unconscious, the
        All-Consciousness of which it is made it is hidden in it, and it is
        evolving it back to its true nature.
      </p>
      <p className="text-indent">
        Mother and Sri Aurobindo taught that what people term ‘evolution’ is the
        return to All-Consciousness and All-Love. Human beings evolve more
        quickly than the other creatures of the earth, especially when they
        choose to cooperate with their own evolution and follow a spiritual path
        to progress more quickly. Mother found that she could use flowers to
        help the people who came to her for guidance and help in their spiritual
        endeavours.{' '}
      </p>
      <p className="text-indent">
        Mother was always conscious. In 1882, at the age of four she was aware
        of the new Consciousness and Light which are now spoken of all over the
        world. Even then she knew that she had a great work to do in the world,
        and soon she wanted to give to other people the new consciousness and
        light that came to her.{' '}
      </p>
      <p className="text-indent">
        By the age of twelve, Mother had gained understanding and mastery over
        the processes of Nature. With these subtle powers, she understood the
        spiritual essence of a flower. She also learned that flowers naturally
        transmit their essence to people, and that people can be helped by
        receiving it. She found that she could transmit subtle messages to
        people by putting them into a flower. She used flowers to help people in
        their spiritual progress.
      </p>
      <p className="text-indent">
        Some examples of the spiritual significances which Mother found in
        flowers are; Abolition of the Ego, Aspiration for Immortality, Balance,
        Cheerfulness, Endurance, Humility, Integral Progress, Material
        Organisation, Quiet Mind, Renunciation of Emotional Desires and Striving
        Towards Wisdom. Mother’s perception of a flower’s spiritual essence
        became another name for the flower. She was able to give significances
        to over 900 flowers. She also gave a brief comment about the meaning of
        each one. All of this information is now available in books and on the
        internet.{' '}
      </p>
      <p className="text-indent">
        In 1914, Mother came to India to meet Sri Aurobindo. They found that
        they both had the same realisations and experiences of the new
        consciousness. In 1920, Mother returned permanently to live and work
        with Sri Aurobindo to bring more of the new consciousness into the earth
        atmosphere and to help people receive it. Sri Aurobindo called this new
        consciousness “Supramental”, because its emergence in man will bring man
        to the living realisation that he is All-consciousness and All-Love, and
        this cannot be done with the limited mind. All-Consciousness is man’s
        next major evolutionary step. They created a yogic process they called
        “Integral Yoga” for achieving this consciousness.{' '}
      </p>
      <p className="text-indent">
        Mother organised and managed the Sri Aurobindo Ashram. Then she created
        the Ashram School for children. She helped and guided increasing numbers
        of people of all ages. She used flowers and their spiritual
        significances constantly and for many different reasons.
      </p>
      <p className="text-indent">
        Mother left her flower teachings for the children of the earth to follow
        on their way to humanity’s next stage of evolution. Today people all
        over the world benefit from Mother’s flower teachings.
      </p>
      <h2>About Integral Yoga Talks</h2>
      <p>
        This book is part of the Integral Yoga Talks project. The Integral Yoga
        Talks project is a website offering which will publish talks by Loretta
        Shartsis on Sri Aurobindo and Mother, both in audio and video, and will
        also make available Loretta’s book compilations as web-books. The
        project is scheduled to be released in stages from 2022–24 under the
        umbrella of Prisma’s new publications format of gift-economy publishing.
      </p>
      <p className="text-indent">
        Over the years, Loretta has given public talks on Sri Aurobindo and
        Mother both in Auroville and abroad, and has recorded two series of
        talks for Auroville Radio. She has published four book-compilations of
        their works. These on-going efforts will now be available worldwide on
        the website{' '}
        <Link to="https://www.integral-yoga-talks.com/en/">
          www.integral-yoga-talks.com
        </Link>
        .
      </p>
      <h2>About Loretta Shartsis</h2>
      <p>
        Loretta Shartsis came to the Sri Aurobindo Ashram in February 1972,
        because she wanted to meet Mother. It was Sri Aurobindo’s 100th
        Centenary year. Loretta met Mother in March and the next day, without
        knowing how it happened, she found herself living at the centre of
        Auroville, with her own room in the Matrimandir Worker’s Camp. From that
        time, her life has been devoted to Mother and Sri Aurobindo. Fifty years
        later, for Sri Aurobindo’s 150th Birth Centenary, the world will have
        the results of her dedicated teaching about Mother and Sri Aurobindo.
      </p>
      <p className="text-indent">
        Loretta came to Mother after growing up with a life of work and
        education as a visual artist. Later she became an attorney, doing
        Supreme Court and Court of Appeals Research and Presentation and court
        trials. In her teaching work she combines a life of aesthetic pursuits
        and the high quality research and public presentation required in a
        court of law.
      </p>
      {/*<h2>The print book</h2>
      <div className="slider fullpage book-slider flow">
        <Carousel {...sliderConf}>
          {resources.map((resource) => (
            <GatsbyImage image={getImage(resource.img)} />
          ))}
        </Carousel>
      </div>*/}
      <h2>Web book, this format</h2>
      <p>
        <i>{about.altTitle}</i>&nbsp;is published as part of an emerging method
        of publishing being developed by Prisma Books. The idea is to deliver
        the high level of writing and design quality that you would find in a
        printed book, and to use advanced web technologies for publishing. This
        is the "web-book" format.
      </p>
      <p className="text-indent">
        <Link to="https://books.prisma.haus/en/web-books">
          Read more about web books →
        </Link>
      </p>
      <h2 id="on-gift-economy">On gift economy</h2>
      <p>
        You can buy the paperback version of the book <i>{about.altTitle}</i> at
        a fixed price. The author and the publisher have also chosen to offer
        the book to read online without a pay-wall or advertisement. This is not
        for free, but available on a Gift-Economy and Pay-What-Feels-Right
        basis. It is an invitation to give what feels right for the value you've
        received from the book and/or the support you want to give us to
        continue doing our work. Publishing in this manner is an experiment in
        abundance where we trust that when we give, we will also receive. We
        want you to enjoy the book, learn from the book, but also support the
        book. If it was worth your time, please consider buying the book or
        contributing.
      </p>
      <p className="text-indent">
        <Link to="https://books.prisma.haus/en/available-on-gift-economy">
          Learn more about publishing on Gift-Economy →
        </Link>
      </p>
      <h2>Prisma Books, the publisher</h2>
      <p>
        Since 1988 Prisma Books of Auroville publishes good old-fashioned,
        beautifully curated books. Prisma Books offers full publishing services
        in design, editorial, printing, artwork, illustrations, photography,
        digital media, exhibition and curatorship.
      </p>
      <p className="text-indent">
        <Link to="https://www.prisma.haus/en/">Prisma website →</Link>
      </p>
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    websiteState: state.websiteState,
  }),
  (dispatch) => ({
    updateWebsiteState(payload) {
      dispatch(updateWebsiteStateFx(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
