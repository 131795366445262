// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
import React from 'react'
import Helmet from 'react-helmet'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import indexImage from '../../images/banners/launch.jpg'
import website from '../../seo/website.json'
import organisation from '../../seo/organisation.json'

import withUrl from '../../methods/withUrl'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const dateModified = new Date().toISOString()
const indexImageWithUrl = withUrl(indexImage)

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/**
  * AboutPage - Implements schema.org AboutPage
  *
  * @example
    <AboutPageSchema data={data}>
  *
  * @see {@link http://schema.org/AboutPage|Schema.org AboutPage Docs}
  */
const AboutPageSchema = ({ data: { cover = {} } = {} }) => {
  const imageUrl =
    typeof cover === 'undefined' ? indexImageWithUrl : withUrl(cover)

  const schemaOrgJSONLD = {
    '@context': 'http://schema.org',
    '@type': 'ContactPage',
    '@id': `${website.nakedUrlWithIntl}/about`,
    url: `${website.nakedUrlWithIntl}/about`,
    name: `About ${website.name}`,
    abstract: website.abstract,
    description: website.description,
    dateCreated: website.created,
    dateModified,
    primaryImageOfPage: {
      '@context': 'http://schema.org',
      '@type': 'ImageObject',
      contentUrl: imageUrl,
    },
    breadcrumbs: [
      { name: 'Home', url: `${website.nakedUrlWithIntl}` },
      {
        name: `About ${website.name}`,
        url: `${website.nakedUrlWithIntl}/about`,
      },
    ],
    publisher: {
      '@type': 'Organization',
      name: organisation.name,
      email: organisation.email,
      telephone: organisation.telephone,
      legalName: organisation.legalName,
      logo: organisation.logo,
      foundingDate: organisation.foundingDate,
      address: {
        addressLocality: organisation.address.addressLocality,
        postalCode: organisation.address.postalCode,
        streetAddress: organisation.address.streetAddress,
      },
      founders: organisation.founders.map(founder => ({
        '@type': 'Person',
        name: founder,
      })),
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: 'customer service',
        telephone: organisation.telephone,
        email: organisation.email,
      },
    },
  }

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  )
}

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default AboutPageSchema
